import React from 'react';
import Footer from '../footer';
// import Header from '../../components/header';
// import { ROUTE_FLAG } from '../../constants';

const aboutUs = ({website}) => {

  return (
    <React.Fragment>
      {/*<div className='headerContainer'>*/}
      {/*  <Header active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage} customStyle='non-sticky'/>*/}
      {/*  /!*<Header customStyle='sticky' active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage}/>*!/*/}
      {/*</div>*/}
      <div className='backgroundImagePaymentPolicy'>


        <div className='PaymentPolicyContainer'>
          About us
        </div>
        <p className='pTextStyle'>

          MedTrail is a Health-Tech company that provides AI powered remote assistant service which helps its clients in
          better managing their practice (Clinic/Hospital and Pharmacy operations) while maintaining the empathy aspects
          of a clinic's/hospital's practice. There are two key components in MedTrail's product suite.

        </p>
        <p className='pTextStyle'>

          1. Smart Assistant is a digital assistant for the doctors that seamlessly blends with the doctor's current
          ways of working and serves them without the need for any habit change. Smart Assistant gets powered by state
          of the art hardware that records a doctor's practice including prescription copy and other patient's medical
          record.

        </p>
        <p className='pTextStyle'>

          2. Smart Pharmacy solution is powered by MedTrail's proprietary predictive AI technology. Smart Pharmacy
          understands a doctor's practice and prescription trends to predict demand and optimize the medicine inventory.
          Using the technology, MedTrail helps its clients efficiently run pharmacy operations on as low as a 3-day
          sales cycle. This makes it extremely easy to manage operations while unlocking the benefits from saved capital
          and space. Smart Pharmacy's sophisticated UX empowers the clinic/hospital staff to dispense accurate medicines
          with the highest efficiency. The net result is thus improved top line and bottom line for pharmacy
          and happy patients for doctors.
        </p>
      </div>
      <Footer website={website}/>

    </React.Fragment>
  );

};


export default aboutUs;
