import React from 'react';
import MedtrailIcon from './images/vectors/medtrail_logo_svg.svg';
import MedTrailIconNew from "!svg-react-loader!./images/vectors/medtrail_logo.svg"
import MyHealthBookIcon from './images/vectors/healthbook-logo.svg';
import { WEBSITES } from './constants';

const FooterBox = ({ title, description, ...props }) => {
  const { logo = '', link, linkText, email, phone,website } = props;
  return (
    <div className={`footer-content ${logo}`}>
      {logo && website==WEBSITES.MEDTRAIL && <MedtrailIcon/>}
      {logo && website==WEBSITES.MYHEALTHBOOK && <div className="footer-healthbook-icon"><MyHealthBookIcon/></div>}
      {logo && website==WEBSITES.DISPENSARY && <MedTrailIconNew/>}
      {logo && website==WEBSITES.DIAGNOSTICS && <MedTrailIconNew/>}
      <div className='footer-title'>{title}</div>
      {email ?
        <div className='footer-desc'><a href={`mailto:${description}`} target="_top">{description}</a></div>
        :
        (phone ?
            <div className='footer-desc'><a href={`tel:${description}`} target="_top">{description}</a></div>
            :
            <div className='footer-desc'>{description}</div>
        )
      }

      {link && linkText &&
      <div className='footer-link'>
        <a href={`${link}`} target='_blank' rel="noopener noreferrer">{linkText}</a>
      </div>
      }
    </div>
  );
};

export default FooterBox;
