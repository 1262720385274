export const PROD_URL = process.env.GATSBY_API_URL;
export const RESUME_UPLOAD_API = `${PROD_URL}/api/v0/careersResume`;
export const CAREERS_API = `${PROD_URL}/api/v0/careers`;
export const REQUEST_DEMO_API = `${PROD_URL}/api/v0/requestDemo`;
export const RESUME_UPLOAD = ['pdf', 'doc'];


export const ROUTE_FLAG = {
  paymentPolicyPage: 'paymentPolicyPage',
  homePage: 'homePage',
};
export const WEBSITE = 'MEDTRAIL';
