import React from 'react';
import FooterBox from './footer-box';
import { Link } from 'gatsby';
import { WEBSITES } from './constants';

const Footer = ({ website,className }) => (
  <footer className={`container-fluid ${className}`} id='footer'>
    <div className='row'>
      <div className='col-md-4 col-sm-12'>
        <FooterBox description='Copyright &copy; 2017, MedTrail. All rights reserved' logo='medtrail-icon' website={website}/>
      </div>
      <div className='col-md-4 col-sm-12 address'>
        <FooterBox title='Address' description='A 16, Mohan Co-operative, New Delhi, 110044'/>
      </div>
      <div className='col-md-2 col-sm-12 email'>
        <FooterBox title='Email' description={website ==WEBSITES.MYHEALTHBOOK?'contact@myhealthbook.in': 'info@medtrail.in'} email={true}/>
      </div>
      <div className='col-md-2 col-sm-12 phone'>
        <FooterBox title='Phone' description='+91-9315400085' phone={true}/>
      </div>

      {website != WEBSITES.MYHEALTHBOOK && <div className='footerBreadCrumbs'>

        <Link to='/about-us' className='linkAndBullet'>
          <div className='bullet'/>
          <div className='footerChild'>About Us</div>
        </Link>


        <Link to='/terms-and-conditions' className='linkAndBullet'>
          <div className='bullet'/>
          <div className='footerChild'>Terms and Conditions</div>
        </Link>


        <Link to='./privacy-policy' className='linkAndBullet'>
          <div className='bullet'/>
          <div className='footerChild'>Privacy Policy</div>
        </Link>


        <Link to='./refund-and-cancellation' className='linkAndBullet'>
          <div className='bullet'/>
          <div className='footerChild'>Refund and Cancellation</div>
        </Link>


        <Link to='./pricing-structure' className='linkAndBullet'>
          <div className='bullet'/>
          <div className='footerChild'>Product / Service Details</div>
        </Link>

      </div>
      }
    </div>
  </footer>
);

export default Footer;
