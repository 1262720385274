import React from 'react';
import Footer from '../footer';
// import Header from '../../components/header';
// import { ROUTE_FLAG } from '../../constants';


const termsAndCondition = ({website}) => {

  return (
    <React.Fragment>
      {/*<div className='headerContainer'>*/}
      {/*  <Header active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage}/>*/}
      {/*</div>*/}


      <div className='backgroundImagePaymentPolicy'>

        <div className='PaymentPolicyContainer'>
          Terms and Conditions
        </div>
        <p className='pTextStyle'>

          The Terms of Use contained hereafter needs to be read, understood & agreed by a user before initiating any
          kind of transaction with us.
        </p>

        <p className='pTextStyle'>
          This website, www.medtrail.in is published and maintained by MedTrail Technologies Private Limited (referred
          as MedTrail hereinafter). When a user accesses, browses or uses this website, User accepts, without
          limitation
          or qualification, the terms and conditions set forth herein below. When the User accesses any other website
          through this website, then such other website may have its own terms and conditions of use which is specific
          to such other website. These Terms of Use and any additional terms posted on this website together
          constitute
          the entire agreement between MedTrail and the User with respect to use of this website. In the case of any
          violation of these terms and conditions, MedTrail reserves its rights to seek all remedies available in law
          and in equity for such violations.
        </p>
        <div className='heading'>Site and its Contents</div>

        <p className='pTextStyle'>

          All materials provided on this website are only for the specific purpose only. User shall not distribute,
          exchange, modify, sell or transmit anything that User copies from this website, including but not limited to
          any text, images, audio and video, for any business, commercial or public purpose. As long as the User is
          complying with the terms of these terms and conditions stated on this website, MedTrail grants to User a
          non–exclusive, non–transferable, limited right to enter, view and use this website. The User agrees not to
          interrupt or attempt to interrupt the operation of this website in any way. Access to certain areas of the
          website may only be available to registered members. All materials including various software are property
          of
          MedTrail and protected by applicable copyright and other intellectual property laws.
        </p>
        <div className='heading'>Ownership</div>
        <p className='pTextStyle'>

          All materials on this website, including but not limited to audio, images, software, text, icons and such
          (the
          “Content”), are protected by the applicable copyright laws. User cannot use the Content, except as specified
          herein. The User agrees to follow all instructions on this website limiting the way the User may use the
          Content. There are a number of proprietary logos, service marks and trademarks displayed on this website
          whether owned/used by MedTrail or otherwise. By displaying them on this website, MedTrail is not granting
          User
          any license to utilize those proprietary logos, service marks, or trademarks, in any manner whatsoever. Any
          unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and
          publicity,
          and civil and criminal statutes.
        </p>
        <div className='heading'>Devices</div>
        <p className='pTextStyle'>

          The User agrees that User will not use any robot, crawler, other automatic device, or a manual process to
          monitor or copy our web pages or the content contained herein without our prior expressed written
          permission.
          User agrees that User will not use any device, software or routine to interfere or attempt to interfere with
          the proper working of this website. User agrees that User will not take any action that imposes an
          unreasonable or disproportionately large load on our infrastructure.
        </p>
        <div className='heading'>Modifications</div>
        <p className='pTextStyle'>

          MedTrail may add to, change or remove any part of these terms and conditions at any time, without notice.
          Any
          changes to these terms and conditions or any terms posted on this website will become applicable as soon as
          they are posted. By continuing to use this website after any changes, the User is indicating the acceptance
          of
          those changes. MedTrail may add, change, discontinue, remove or suspend any other Content posted on this
          website, including features and specifications, process flow depicted on the website, temporarily or
          permanently, at any time, without notice and without any liability. MedTrail reserves the right to undertake
          all necessary steps to ensure that the security, safety and integrity of MedTrail systems remain
          well–protected. Towards this end, MedTrail may take various steps to verify and confirm the authenticity,
          enforceability and validity of the transactions committed by the User.
        </p>
      </div>
      <Footer website={website}/>

    </React.Fragment>
  );

};

export default termsAndCondition;
