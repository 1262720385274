import React from 'react';
import Footer from '../footer';
import { Link } from 'gatsby';
// import MedtrailIcon from '../../images/vectors/medtrail_logo_svg.svg';
// import Header from '../../components/header';
// import { ROUTE_FLAG } from '../../constants';


const privacyPolicy = ({website}) => {

  return (
    <React.Fragment>
      {/*<div className='headerContainer'>*/}
      {/*  <Header active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage}/>*/}
      {/*</div>*/}

      <div className="backgroundImagePaymentPolicy privacy-policy-container">
        <div className="PaymentPolicyContainer">Privacy Policy</div>
        <p className="pTextStyle">
          <b>MedTrail Technologies Private Limited</b> is​ a private limited
          company incorporated under the Companies Act, 2013, having its
          registered office at A 16, Mohan Co-operative, Sarita Vihar, Delhi -
          110044 (hereinafter referred to as <b>​“We”</b>​​, <b>​“Company”</b>,
          <b>“Our”</b> or <b>“Us”</b>​,​ where such expression shall unless
          repugnant to the context thereof, be deemed to include its respective
          legal heirs, representatives, administrators, permitted successors and
          assigns). This privacy policy (<b>“Privacy Policy”</b>) (i) explains
          the type of the User Information (<i>as defined below</i>) which
          includes Personal Information (<i>as defined below</i>) and Sensitive
          Personal Data or Information (<i>as defined below</i>) collected from
          the Users (<i>as defined below</i>); (ii) states the purpose, means
          and modes of collection, usage, processing, retention and protection
          of the User Information; and (iii) how and to whom we will disclose
          such User Information. We have created this Privacy Policy to ensure
          our steady commitment to the privacy of the User Information of the
          Users who interact with our Services (as defined below in Clause 1).
        </p>
        <p className="pTextStyle">
          In order to have access to all the features, benefits and services on
          or through our Platform. A User is required to create an account on
          Our Platform in order to avail Service(s) of the Platform. To create
          an account, a User is required to provide the following information,
          which such User recognizes and expressly acknowledges is personal
          information allowing others, including Us, to identify the User: name,
          username/log-in particulars, gender, country, address, ZIP/postal
          code, age, email, and phone number. In addition, at the time of a
          registered User availing of the Services on or through the Platform,
          the User is required to provide certain sensitive personal information
          which includes its bank account or card details for undertaking
          transactions on the Platform for availing the Services. Further, in
          respect of the Users availing services of any Doctor/ Practitioner
          enlisted on our Platform, such Users may be additionally asked to
          provide the details about previous medical history, medical and health
          records and prescriptions for the medications that have been issued to
          such User by any registered medical practitioner/ Doctor.
        </p>
        <p className="pTextStyle">
          <b>DISCLAIMER</b>- BY USING AND ACCESSING THE PLATFORM AND BY AVAILING
          THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE
          DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND
          POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THIS
          PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING,
          DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE
          RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE
          TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF
          YOU DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY
          OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE
          SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) YOU REPRESENT
          THAT YOU ARE AUTHORIZED BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT
          THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II)
          CONSENT ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE
          AND DISCLOSURE OF SUCH INDIVIDUAL’S INFORMATION. YOU MAY NOTE THAT
          THIS PRIVACY POLICY IS BASED ON THE APPLICABLE LAWS OF INDIA AND GOOD
          DATA PRIVACY PRACTICES ADOPTED BY THE COMPANY IN INDIA AND MAY BE
          FOUND DEFICIENT WITH RESPECT TO CERTAIN PRIVACY LAWS OF SOME OTHER
          COUNTRIES. BY VISITING THE PLATFORM, YOU AGREE TO BE BOUND BY THE
          TERMS AND CONDITIONS OF THIS PRIVACY POLICY. BY MERE USE OF THE
          ELECTRONIC MEDIA PLATFORM, YOU EXPRESSLY CONSENT TO OUR USE AND
          DISCLOSURE OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS
          PRIVACY POLICY.
        </p>
        <p className="pTextStyle">
          Your use of and access to the Services is subject to this Privacy
          Policy and our Terms of Service <i>(as defined below)</i>. Any
          capitalized term used, but not defined, in this Privacy Policy shall
          have the meaning attributed to it in our Terms of Service.
        </p>
        <p className="pTextStyle">
          The headings used herein are only for the purpose of arranging the
          various provisions of the Privacy Policy. The headings are for the
          purpose of reference only and shall not be interpreted to limit or
          expand the provisions of the clauses contained therein.
        </p>
        <div className="heading uppercase">1. Definitions</div>
        <p className="pTextStyle">
          In this Privacy Policy, unless the context otherwise requires, the
          terms defined shall bear the meanings assigned to them below, and
          their cognate expressions shall be construed accordingly.
        </p>
        <ol>
          <li>
            <b>“Doctor/ Practitioner”</b> shall mean a ‘registered medical
            practitioner’ with the Medical Council of India or concerned State
            Medical Council who are registered with or on the Platform.
          </li>
          <li>
            <b>“Personal Information”</b> shall have the same meaning as given
            in Rule 2(1)(i) of the SPI Rules.
          </li>
          <li>
            <b>“Intermediaries Guidelines”</b> shall mean the Information
            Technology (Intermediaries Guidelines) Rules, 2011.
          </li>
          <li>
            <b>“SPI Rules”</b> means the Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal Data or
            Information) Rules, 2011
          </li>
          <li>
            <b>“Sensitive Personal Data or Information”</b> shall have the same
            meaning as given in Rule 3 of the SPI Rules which will also include
            and the health and medical data submitted/ provided on the Platform
            by the Doctor/ Practitioner relating to any concerned person in
            respect of which the Doctor/ Practitioner has obtained prior consent
            of such concerned person for collecting, storing and transmitting
            such health and medical data with the third parties.
          </li>
          <li>
            <b>“Platform”</b> shall collectively mean ‘Website
            (www.medtrail.in)’, ‘WebPortal (arya.io)’, ‘Mobile Application’
            (MedTrail and Myhealthbook) collectively and severally.
          </li>
          <li>
            <b>​“We”</b>​, <b>“Our”</b>​, <b>​“Company”</b>, <b>​“MedTrail”</b>{' '}
            and <b>“Us”</b>​ shall mean and refer to MedTrail Technologies
            Private Limited.
          </li>
          <li>
            <b>“You”</b>, <b>“Your”</b>, <b>“Yourself”</b> and <b>“User”</b>{' '}
            shall mean and refer to all natural persons and legal entities/
            companies through their authorised representatives who visit access
            or use the Platform/ Medtrail’s Services and also. include
            individuals who avail services of any registered medical
            practitioner/ hospital/ clinics (registered with Medtrail in
            accordance with the Terms of Service) and submits their information
            and details to such registered medical practitioner/ hospital/
            clinics (registered with Medtrail in accordance with the Terms of
            Service).
          </li>
          <li>
            <b>“Services”</b> shall mean the healthcare system and facilitation
            services provided on or through the Platform or any hardware
            provided by MedTrail and contextual information transmitted to/
            received from Users via various communication channels including but
            not limited to SMS, WhatsApp.
          </li>
          <li>
            <b>“User Information”</b> shall mean, collectively, all information
            that a User submits/ provides/ publishes on the Platform so as to
            enable or use the Services and includes Personal Information,
            Sensitive Personal Data or Information.
          </li>
          <li>
            <b>“Website”​</b> shall mean and refer to <b>www.medtrail.in</b>,​
            the <b>“Application”​</b> shall refer to MedTrail app and
            ‘Myhealthbook’ available on Android Play Store or iOS App Store and{' '}
            <b>“Web Portal”</b> shall refer to ​<b>arya.io</b>.
          </li>
          <li>
            “Terms of Service” shall mean the terms and conditions of Platform
            for its use, access and/ or availing of the Services as provided on
            the Platform and which can be accessed at{' '}
            <Link to="/terms-and-conditions">this link.</Link>
          </li>
        </ol>
        <div className="heading uppercase">2. Why this privacy policy?</div>
        <p>This Privacy Policy is published in compliance with, inter alia,</p>
        <ol>
          <li>Section 43A of the Information Technology Act, 2000;</li>
          <li>Regulation 4 of the SPI Rules; and</li>
          <li>Regulation 3(1) of the Intermediaries Guidelines.</li>
        </ol>
        <p>This Privacy Policy states the following:</p>
        <ol start="4">
          <li>
            The type of information collected from the Users, including
            Sensitive Personal Data or Information;
          </li>
          <li>
            The purpose, means and modes of usage of such information; and
          </li>
          <li>How and to whom we will disclose such information</li>
        </ol>

        <div className="heading uppercase">3. General</div>
        <ol>
          <li>
            The User unequivocally agrees that the Privacy Policy constitute a
            legally binding agreement between the User and the Company, and that
            the User shall be subject to all the rules, guidelines, policies,
            terms, and conditions applicable in respect of any Service,
            including the Terms of Service, which shall be deemed to be
            incorporated into the terms of this Privacy Policy, and shall be
            treated as part of the same. The User acknowledges and agrees that
            no signature or express act is required to make these terms of the
            Privacy Policy binding on the User, and that the User’s by using,
            accessing, browsing or visiting any part of the Platform or availing
            any Service(s) constitutes the User’s full and final acceptance of
            the Privacy Policy.
          </li>
          <li>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures. Further, this document
            is published in accordance with the provisions of the SPI Rules and
            Intermediaries Guidelines.
          </li>
          <li>
            The terms <b>“Party”</b> and <b>“Parties”</b> shall respectively be
            used to refer to the User and the Company individually and
            collectively, as the context so requires.
          </li>
          <li>
            The headings of each section in this Policy are only for the purpose
            of organizing the various provisions under this Privacy Policy in an
            orderly manner, and shall not be used by either Party to interpret
            the provisions contained herein in any manner. Further, it is
            specifically agreed to by the Parties that the headings shall have
            no legal or contractual value.
          </li>
          <li>
            The Parties expressly agree that the Company retains the sole and
            exclusive right to amend or modify the Privacy Policy without any
            prior permission from the User. The information in respect of such
            amendment and modification will be posted on the Platform for the
            User, keeping in mind best practises and laws set by the
            State/Central Government of India, and the User expressly agrees
            that any such amendments or modifications shall come into effect
            immediately. The User has a duty to periodically check the Privacy
            Policy, and stay updated on their provisions and requirements. If
            the User continues to use Platform following such a change, the User
            will be deemed to have consented to any and all amendments/
            modifications made to the Privacy Policy. In so far as the User
            complies with the Privacy Policy and Terms of Service, the User is
            granted a personal, non-exclusive, non-transferable, revocable,
            limited privilege to enter, access and use the Platform.
          </li>
        </ol>
        <div className="heading uppercase">
          4. Collection and handling of personal information
        </div>
        <p className="pTextStyle">
          Privacy of the Parties is of prime importance to MedTrail and all
          services are strictly designed within the jurisdiction of laws defined
          by the Government of India.
        </p>
        <p className="pTextStyle">
          Generally, the Services require us to know who you are so that we can
          best meet your needs. When you access the Services, we may ask you to
          voluntarily provide us with certain information that personally
          identifies you or could be used to personally identify you. Without
          prejudice to the generality of the above, information collected by us
          from you may include (but is not limited to) the following:
        </p>
        <ol>
          <li>Contact data (such as your email address and phone number);</li>
          <li>Username and passwords;</li>
          <li>
            Demographic data (such as your gender, age, your date of birth and
            your pin code);
          </li>
          <li>
            Data regarding your usage of the Services and history of the
            appointments and other transactions made by or with you through the
            use of Services;
          </li>
          <li>
            Health or medical data (such as your past medical history and
            conditions which shall include past and present diseases, allergies,
            previous treatments, diagnostic reports, prescriptions and
            medication history, or other information uploaded/published by the
            User voluntarily on MedTrail);
          </li>
          <li>Location information, including Your GPS location;</li>
          <li>
            Any other information that you voluntarily choose to provide to us
            (such as information shared by you with us through emails or
            letters, your work details, your family details).
          </li>
        </ol>
        <p className="pTextStyle">
          The information collected from you by MedTrail shall constitute
          ‘Personal Information’ or ‘Sensitive Personal Data Information’ under
          the SPI Rules.
        </p>
        <p className="pTextStyle">
          “Personal Information” is defined under the SPI Rules to mean any
          information that relates to a natural person, which, either directly
          or indirectly, in combination with other information available or
          likely to be available to a body corporate, is capable of identifying
          such a person.
        </p>
        <p className="pTextStyle">
          The SPI Rules further define “Sensitive Personal Data or Information”
          of a person to mean Personal Information about that person relating
          to:
        </p>
        <ol>
          <li>passwords</li>
          <li>
            financial information such as bank accounts, credit and debit card
            details or other payment instrument details;
          </li>
          <li>physical, physiological and mental health condition;</li>
          <li>sexual orientation;</li>
          <li>medical records and history;</li>
          <li>biometric information;</li>
          <li>
            information received by body corporate under lawful contract or
            otherwise;
          </li>
          <li>
            visitor details as provided at the time of registration or
            thereafter; and
          </li>
          <li>call data records.</li>
        </ol>
        <div className="heading uppercase">5. Privacy Statements</div>
        <ol>
          <li>
            <span>The User expressly agrees and acknowledges:</span>
            <ul className="list-style-square margin-x-1">
              <li>
                Information that is freely available in the public domain or
                accessible under the Right to Information Act, 2005 or any other
                law will not be considered as ‘Personal Information’ or
                ‘Sensitive Personal Data or Information’.
              </li>
              <li>
                About awareness that the Company shall automatically track
                information about the User based on the User’s IP address and
                the User’s behaviour on MedTrail, and the User expressly
                consents to the same. The User is aware and consents that this
                information may be used for market research, product development
                and also to do internal research on user demographics,
                interests, and behaviour, to enable the Company to better
                understand, and cater to the interests of the Users. Further,
                the User is expressly made aware that such information may
                include the User’s computer & web browser information, the
                User’s IP address, mobile device details etc. The linkage
                between User’s IP address and User’s personally identifiable
                information is not shared with or disclosed to third parties.
                Notwithstanding the above, MedTrail may share and/or disclose
                some of the aggregate findings (not the specific data) in
                anonymized form (i.e., non-personally identifiable) for market
                research and new feature development.
              </li>
              <li>
                About awareness that any and all information pertaining to the
                User collected by the Company, whether or not directly provided
                by the User to the Company, including but not limited to
                personal correspondence such as emails or letters or SMS or
                WhatsApp, feedback from other users or third parties regarding
                the User’s activities or postings on MedTrail, etc., may be
                collected and compiled by the Company into a file/folder
                specifically created for/allotted to the User, and the User
                hereby expressly consents to the same.
              </li>
              <li>
                About awareness that the contact information provided to the
                Company may be used to send the User offers and promotions,
                whether or not based on the User’s previous interests, and the
                User hereby expressly consents to receiving the same. The User
                may choose to unsubscribe from promotional communications by
                clicking on the ‘unsubscribe’ link provided at the end of every
                such promotional communication.
              </li>
              <li>
                About awareness that the Company may occasionally request the
                User to complete optional online surveys. These surveys may
                require the User to provide contact information and demographic
                information (like zip code, age, income bracket, sex, etc.). The
                User is aware that this information is used to improve/customise
                MedTrail for the benefit of the User, and providing all users of
                MedTrail with services that the Company believes they might be
                interested in availing of.
              </li>
              <li>
                About awareness that the Company may keep records of electronic
                communications and telephone calls received and made for support
                or other purposes for the purpose of administration of Services,
                customer support, research and development and for better
                assistance to Users.
              </li>
              <li>
                About awareness that the Company may occasionally request the
                User to write reviews for services availed of by the User from
                the Website/Application/ Web Portal. The User is aware that such
                reviews will help potential users of MedTrail from availing of
                the Services, and the User hereby expressly authorizes the
                Company to publish any and all reviews written by the User on
                the Website/Application/Web Portal.
              </li>
              <li>
                Nothing contained herein shall be deemed to compel the Company
                to store, upload, publish, or display in any manner
                content/reviews/surveys/feedback submitted by the User, and the
                User hereby expressly authorizes the Company to remove from
                MedTrail any such content, review, survey, or feedback submitted
                by the User, without cause or being required to notify the User
                of the same.
              </li>
              <li>
                Generation and collection of ‘Sensitive Personal Data or
                Information’ in accordance with the Information Technology Act,
                2000 as amended from time to time and allied rules requires the
                User’s express consent. By affirming assent to this Privacy
                Policy at the time of registration, the User provides consent to
                such generation and collection as required under applicable laws
                for the above mentioned purposes.
              </li>
              <li>
                The User is responsible for ensuring that handwriting is legible
                and maintaining the accuracy of the information submitted to the
                Company. The User may correct, delete inaccuracies, or amend
                information by contacting the Company through{' '}
                <b className="underline">support@medtrail.in</b>. The Company
                will make good faith efforts to make requested changes in the
                databases as soon as reasonably practicable. If the User
                provides any information that is untrue, inaccurate, out of date
                or incomplete (or becomes untrue, inaccurate, out of date or
                incomplete), or the Company has reasonable grounds to suspect
                that the information provided by the User is untrue, inaccurate,
                out of date or incomplete, the Company may, at its sole
                discretion, discontinue the provision of the Services to you as
                per the provisions laid down in the Terms. There may be
                circumstances where MedTrail will not correct, delete or update
                your Personal Data, including (a) where the Personal Data is
                opinion data that is kept solely for evaluative purpose; and (b)
                the Personal Data is in documents related to a prosecution if
                all proceedings relating to the prosecution have not been
                completed.
              </li>
              <li>
                If the User wishes to delete his/her account or request that the
                Company no longer uses the User’s Information to provide
                Services, the User may contact the Company on{' '}
                <b className="underline">support@medtrail.in</b>. We shall not
                retain such information for longer than is required for the
                purposes for which the information may lawfully be used or is
                otherwise required under any other law for the time being in
                force. After a period of time, your data may be anonymized and
                aggregated, and then may be held by us as long as necessary for
                us to provide our Services effectively, but our use of the
                anonymized data will be solely for analytic purposes. Please
                note that your withdrawal of consent, or cancellation of account
                may result in MedTrail being unable to provide you with its
                Services or to terminate any existing relationship MedTrail may
                have with you.
              </li>
              <li>
                If you wish to opt-out of receiving non-essential communications
                such as promotional and marketing-related information regarding
                the Services, please send us an email at{' '}
                <b className="underline">support@medtrail.in</b>.
              </li>
            </ul>
          </li>
          <li>
            <span>
              Practitioner’s note (
              <i>this section is applicable to doctors/practitioners only</i>)
            </span>
            <ul className="list-style-square margin-x-1">
              <li>
                As part of the registration as well as the application creation
                and submission process that is available to
                Doctors/Practitioners on MedTrail, certain information,
                including Personal Information or Sensitive Personal Data or
                Information is collected from the Doctors/Practitioners.
              </li>
              <li>
                All the statements in this Privacy Policy apply to all
                Doctors/Practitioners, and all Doctors/Practitioners are
                therefore required to read and understand the privacy statements
                set out herein prior to submitting any Personal Information or
                Sensitive Personal Data or Information to MedTrail, failing
                which they are required to refrain from using/ accessing the
                Platform and availing the Services.
              </li>
              <li>
                MedTrail automatically enables the listing of
                Doctor/Practitioners’ information on its Website for any
                ‘Doctor’ or ‘Clinic’ added to a practice using its software. The
                Doctor/Practitioner information listed on Website is displayed
                when Users search for doctors on Website, and the
                Doctor/Practitioner information listed on Website is used by
                Users to request for doctor appointments. Any personally
                identifiable information of the Practitioners listed on the
                Website is not generated by Us and is provided to Us by
                Doctors/Practitioners who wish to enlist themselves on the
                Website, or is collected by Us from the public domain. We
                display such information on its Website on an as-is basis making
                no representation or warranty on the accuracy or completeness of
                the information. As such, we strongly encourage
                Doctors/Practitioners to check the accuracy and completeness of
                their information from time to time, and inform us immediately
                of any discrepancies, changes or updates to such information. We
                will, however, take reasonable steps to ensure the accuracy and
                completeness of this information.
              </li>
              <li>
                The information and data collected by the Doctor/ Practitioner
                and provided/ submitted on the Platform also include the health
                or medical data of any concerned person (such as the past
                medical history and conditions which shall include past and
                present diseases, allergies, previous treatments, diagnostic
                reports, prescriptions and medication history, or other
                information). Such health and medical data of the concerned
                person qualify as Personal Information or Sensitive Personal
                Data or Information of such concerned person. It shall be duty
                of the Doctor/ Practitioner (i) to inform the concerned person
                prior to collecting and storing such health and medical data on
                the Platform about the capturing, storage and transmission of
                the health and medical data sharing of such health and medical
                data with third parties; and (ii) to obtain prior express
                consent of the concerned person in respect of such capturing,
                storage and transmission of the health and medical data and
                sharing of such health and medical data with third parties. The
                Doctor/ Practitioner shall ensure that such collection of the
                health and medical data of the concerned person and uploading of
                such health and medical data is not in violation of the
                ‘Telemedicine Guidelines’ as provided under the Indian Medical
                Council(Professional conduct, Etiquette and Ethics) Regulations,
                2002.
              </li>
            </ul>
          </li>
        </ol>
        <div className="heading uppercase">6. Our use of your information</div>
        <p className="pTextStyle">
          All the information provided to MedTrail by a User, including Personal
          Information or any Sensitive Personal Data or Information, is
          voluntary. Such information in its original form will never be shared
          with any third parties without an explicit consent from the User. You
          understand that MedTrail may use certain information of yours, which
          has been designated as Personal Information or ‘Sensitive Personal
          Data or Information’ under the SPI Rules for the following purposes:
        </p>
        <ol>
          <li>providing you the Services;</li>
          <li>taking product & Service feedback;</li>
          <li>for offering new products or services;</li>
          <li>
            for analysing software usage patterns for improving product design
            and utility;
          </li>
          <li>
            for providing the services of generating alerts/reminders/SMS for
            the medicines and also for internal record.
          </li>
          <li>
            for market research, statistical analysis and business intelligence
            purposes in an aggregated or non-personally identifiable form on
            commercial basis,
          </li>
          <li>debugging customer support related issues; and</li>
          <li>
            for communication purposes so as to provide You a better way of
            booking appointments and for obtaining feedback in relation to the
            Practitioners and their practice.
          </li>
        </ol>
        <p className="pTextStyle">
          We may use your tracking information such as IP addresses, and or
          Device ID to help identify you and to gather broad demographic
          information.
        </p>
        <p className="pTextStyle">
          In case we are acquired by or merged with another company, We shall
          transfer information disclosed by You and information about You to the
          company we are acquired by or merged with. In the event of a merger or
          acquisition, We shall notify You by email/by putting a prominent
          notice on the Website/Application before Your Personal Information is
          transferred and becomes subject to a different privacy policy.
        </p>
        <p className="pTextStyle">
          The Users expressly agree and acknowledge that the Company collects
          and stores the User’s Personal Information and/or Sensitive Personal
          Information in a secure cloud based platform which is provided by the
          User from time to time on the Website/Application/ Web Portal or while
          using other Services. The User is aware that this information will be
          used by the Company to deliver its services and help customize/improve
          MedTrail experience safer and easier but no personally identifiable
          information will be shared with any third party under any
          circumstances without User’s explicit consent unless directed by the
          law.
        </p>

        <div className="heading uppercase">7. Confidentiality and security</div>
        <ol>
          <li>
            Your information is regarded as confidential and therefore shall not
            be divulged to any third parties, unless legally required to do so
            to the appropriate authorities, or if necessary for providing the
            services of the Website/Application/ Web Portal.
          </li>
          <li>
            Your Personal Information/Sensitive Personal Data is maintained by
            Us in an electronic form on our equipment, and on the equipment of
            our employees. Such information may also be converted to physical
            form from time to time.
          </li>
          <li>
            <span className="underline">
              <b>People who can access your Personal Information</b>
            </span>
            <ul className="list-style-square margin-x-1">
              <li>
                User Information will be processed by our employees, authorised
                staff or agents, on a need to know basis, depending on the
                specific purposes for which the User Information has been
                collected by Us.
              </li>
              <li>
                Part of the functionality of the Company is assisting the
                doctors to maintain and organise such information. The Company
                may, therefore, retain and submit all such records to the
                appropriate authorities, or to doctors who request access to
                such information.
              </li>
              <li>
                Part of the functionality of the Company is assisting the
                patients, and other stakeholders (like doctors, dispensaries,
                customer care executives) to access information relating to
                them. The Company may, therefore, retain and submit all such
                records to the relevant patients, or the stakeholders.
              </li>
            </ul>
          </li>
          <li>
            <span className="underline">Security Practices:</span> We treat data
            as an asset that must be protected against loss and unauthorised
            access. We employ many different security techniques to protect such
            data from unauthorized access by members inside and outside MedTrail
            Technologies Private Limited. We follow generally accepted industry
            standards to protect the User Information submitted to Us and
            information that We have accessed, including managerial, technical,
            operational and physical security control measures. The Users
            indemnifies MedTrail from any legal liabilities in case of any
            security breaches beyond the control of Our standard security
            procedures.
          </li>
          <li>
            <span className="underline">Measures We expect you to take:</span>{' '}
            It is important that you also play a role in keeping your User
            Information safe and secure. You are responsible for keeping your
            login details/ email/ username confidential and for any use of your
            account. If You use a shared or public computer, never choose to
            have your login details/email address/ username remembered and make
            sure to log out of your account every time you leave the computer.
          </li>
          <li>
            <span className="underline">
              Unauthorised use of User’s account:
            </span>{' '}
            We do not undertake any liability for any unauthorized use of your
            account and login details/ email address/ username. If you suspect
            any unauthorized use of your account, you must immediately notify Us
            by sending an email to{' '}
            <b className="underline">support@medtrail.in</b> You shall be liable
            to indemnify Us due to any loss suffered by them due to such
            unauthorized use of your account and login details/ email address/
            username.
          </li>
          <li>
            Notwithstanding the above, the Company is not responsible for the
            confidentiality, security or distribution of your Personal
            Information by third parties outside the scope of our agreement with
            such third parties. Further, the Company shall not be responsible
            for any breach of security or for any actions of any third parties
            or events that are beyond the reasonable control of the Company
            including but not limited to the, acts of government, computer
            hacking, unauthorised access to computer data and storage device,
            computer crashes, breach of security and encryption.
          </li>
        </ol>
        <div className="heading uppercase">8. Background Mode</div>
        <p className="pTextStyle">
          On mobile devices, some components of the MedTrail application will
          run in background mode to connect to MedTrail’s hardware and transfer
          data to the mobile device over Bluetooth. This is to ensure that data
          from MedTrail's hardware is synced to the mobile app, sent to
          MedTrail’s cloud and processed even while the application is in
          background mode.
        </p>
        <div className="heading uppercase">
          9. Rentention of your personal data
        </div>
        <p className="pTextStyle">
          In accordance with applicable laws, We will use the User Information
          for as long as necessary to satisfy the purposes for which such User
          Information was collected (as described in Section 4 above) or to
          comply with applicable legal requirements.
        </p>
        <div className="heading uppercase">10. Your rights</div>
        <p className="pTextStyle">
          You have the right to access, review and request a physical or
          electronic copy of information held about you. You also have the right
          to request information on the source of your Personal
          Information/Sensitive Personal Information.
        </p>
        <div className="heading uppercase">
          11. Children's and minor's policy
        </div>
        <p className="pTextStyle">
          We strongly encourage parents and guardians to supervise the online
          activities of their minor children and consider using parental control
          tools available from online services and software manufacturers to
          help provide a child-friendly online environment. These tools can also
          prevent minors from disclosing their name, address, and other
          personally identifiable information online without parental
          permission. Although the Company’s Platform are not intended for use
          by minors, We respect the privacy of minors who may inadvertently use
          the internet or the Platform.
        </p>
        <div className="heading uppercase">12. Consent to this policy</div>
        <p className="pTextStyle">
          You acknowledge that this Privacy Policy is a part of the Terms of
          Service of the Website and the other Services, and you unconditionally
          agree that becoming a User of the Website, the App and its Services
          signifies your assent to this Privacy Policy. Your visit to the
          Website, use of the App and use of the Services is subject to this
          Privacy Policy and the Terms of Use.
        </p>
        <div className="heading uppercase">13. Cookies</div>
        <p className="pTextStyle">
          We use data collection devices such as "Cookies" on certain pages of
          our Website/Applications/Web Portal. Cookies are small files sited on
          your hard drive that assist us in providing customized services. We
          also offer certain features that are only available through the use of
          a “Cookie”. Cookies can also help us provide information which is
          targeted to your interests. Cookies may be used to identify logged in
          or registered users.
        </p>
        <div className="heading uppercase">
          14. Amendments or change to privacy policy
        </div>
        <p className="pTextStyle">
          MedTrail may update this Privacy Policy at any time, with or without
          advance notice. In the event there are significant changes in the way
          MedTrail treats User Information, or in the Privacy Policy itself,
          MedTrail will display a notice on the Website or send Users an email,
          as provided for above, so that you may review the changed terms prior
          to continuing to use the Services. As always, if you object to any of
          the changes to our terms, and you no longer wish to use the Services,
          you may contact <b className="underline">support@medtrail.in</b> to
          deactivate your account. Unless stated otherwise, MedTrail’s current
          Privacy Policy applies to all information that MedTrail has about you
          and your account.
        </p>
        <p className="pTextStyle">
          If a User uses the Services after a notice of changes has been sent to
          such User or published on the Website/Application/Web Portal, such
          User hereby provides his/her/its consent to the changed terms.
        </p>
        <div className="heading uppercase">
          15. Address for privacy questions
        </div>
        <p className="pTextStyle">
          Should you have questions about this Privacy Policy or MedTrail’s
          information collection, use and disclosure practices, you may contact
          the Data Protection Officer appointed by MedTrail. We will use
          reasonable efforts to respond promptly to any requests, questions or
          concerns, which you may have regarding our use of your Personal
          Information.
        </p>
        <p className="pTextStyle">
          If you have any grievance with respect to our use of your information,
          you may communicate such grievance to the Data Protection Officer:
        </p>
        <div className="privacy-policy-contact-info">
          <p className="margin-x-0">
            <b>Name:</b> Nikhil Agrawal
          </p>
          <p className="margin-x-0">MedTrail Technologies Private Limited,</p>
          <p className="margin-x-0">
            A-16, 2nd Floor, Mohan Co-operative, New Delhi-110044
          </p>
          <p className="margin-x-0">
            <b>Phone:</b> +91 8586999698
          </p>
          <p className="margin-x-0">
            <b>Email:</b> contact@medtrail.in
          </p>
        </div>
      </div>
      <Footer website={website} />
    </React.Fragment>
  );

};

export default privacyPolicy;
