import React from 'react';
import MedTrailIcon from '!svg-react-loader!../images/vectors/medtrail_logo_svg.svg';
import { Link } from 'gatsby';
import { ROUTE_FLAG } from '../constants';

const Header = ({ customStyle = 'non-sticky', active, routeFlag, paymentPageContainer = null }) => (
  <>
    <header className={`header container-fluid ${customStyle}`} id='navbar'>
      <div className={`row align-items-center`}>
        <div className='col-md-4 header-title'>
          {routeFlag === ROUTE_FLAG.homePage ? (
            <span className="cursor-pointer" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <MedTrailIcon/>
          </span>
          ) : (
            <Link to='/'>
            <span className="cursor-pointer">
            <MedTrailIcon/>
          </span>
            </Link>
          )}
        </div>
        {routeFlag === ROUTE_FLAG.paymentPolicyPage ? (
          <div className='col-md-8' style={{ float: 'right' }}>
            <ul className="nav-ul">
              <Link to='/' className='routeLinkColor'>
                <li className={`nav-li ${active}`} id="nav-home">
                  Home
                </li>
              </Link>
              <Link to='#doctor' className='routeLinkColor'>
                <li className="nav-li" id="nav-doctor">
                  Doctors
                </li>
              </Link>
              <Link to='#hospital' className='routeLinkColor'>
                <li className="nav-li" id="nav-hospital">
                  Hospitals
                </li>
              </Link>
              <Link to='#careers' className='routeLinkColor'>
                <li className="nav-li" id="nav-careers">
                  Careers
                </li>
              </Link>
            </ul>
          </div>
        ) : (
          <div className='col-md-8'>
            <ul className="nav-ul">
              <li className={`nav-li ${active}`} id="nav-home">
                Home
              </li>
              <li className="nav-li" id="nav-doctor">
                Doctors
              </li>
              <li className="nav-li" id="nav-hospital">
                Hospitals
              </li>
              <li className="nav-li" id="nav-careers">
                Careers
              </li>
            </ul>
          </div>
        )}

      </div>
    </header>
  </>

);

export default Header;
