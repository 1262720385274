import React from 'react';
import Footer from '../footer';
// import Header from '../../components/header';
// import { ROUTE_FLAG } from '../../constants';


const refundAndCancellation = ({website}) => {

  return (
    <React.Fragment>
      {/*<div className='headerContainer'>*/}
      {/*  <Header active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage}/>*/}
      {/*</div>*/}

      <div className='backgroundImagePaymentPolicy'>

        <div className='PaymentPolicyContainer'>
          Refund and Cancellation
        </div>
        <p className='pTextStyle'>

          MedTrail offers its solution and services on a subscription basis. Once a User chooses to avail any solution
          by MedTrail, they duly enter into an agreement, and are liable to pay an upfront fee as well as a recurring
          fee. Once the lock-in period is over, should a User choose to discontinue the use of MedTrail solution and
          service, the refund and cancellation shall be governed by the terms and conditions stipulated in the
          mutually
          signed agreement.

        </p>
      </div>
      <Footer website={website}/>

    </React.Fragment>
  );

};

export default refundAndCancellation;
